<template>
  <div class="max-w-7xl mx-auto">
    <NavGeneral />
    <ProveedorInfo />
  </div>
</template>
<script>
import NavGeneral from "../components/Proveedor/NavGeneral.vue";
import ProveedorInfo from "../components/Proveedor/ProveedorInfo.vue";
export default {
  components: { NavGeneral, ProveedorInfo },
};
</script>
<style >
</style>