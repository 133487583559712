<template>
  <div class="my-10 padding w-full">
    <div class="bg-primario w-full flex justify-center items-center rounded-xl py-4 px-2 ml-auto mr-auto">
      <div class="flex justify-center items-center w-max mx-auto">
        <i class="fi fi-rr-comment-dollar flex justify-center items-center text-5xl text-white"></i>
      </div>
        <div class="w-max flex flex-col justify-center items-center mx-auto">
        <p class=" text-white text-xl font-light w-max leading-none">Vende aqui</p>
        <p class="text-white text-xl font-semibold leading-none w-max">lo que ya no usas</p>
        <p class="text-white text-sm font-light mt-2 w-max leading-none">Publica 100% gratis</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name: 'SelleVehicle'
}
</script>