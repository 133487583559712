<template>
    <div>
        <NavGeneral/>
        <Vehiculo/>
    </div>
</template>
<script>
import NavGeneral from "../components/Generales/NavGeneral.vue";
import Vehiculo from "../components/Generales/Generales.vue";
export default {
    components:{
        NavGeneral,
        Vehiculo
    }
}
</script>
<style scoped>
    
</style>