<template>
    <div>
        <NavGeneral/>
        <Vencimientos/>
    </div>
</template>
<script>
import NavGeneral from "../components/Vencimientos/NavGeneral.vue";
import Vencimientos from "../components/Vencimientos/Vencimeintos.vue";
export default {
    components:{
        NavGeneral,
        Vencimientos
    }
}
</script>
<style scoped>
    
</style>