<template>
    <div class="padding pt-3 flex justify-between items-center">
        <router-link to="/polizas" >
            <i   class="fi fi-rr-arrow-left text-primario text-2xl flex justify-center items-center"></i>
        </router-link>
        <div class="bg-primario bg-opacity-10 text-primario rounded-full px-4 py-1">
            Vida
        </div>
        <i class="fi fi-rr-menu-dots-vertical flex justify-center items-center text-primario"></i>
    </div>
</template>
<script>
export default {
    name: "NavGeneral"
}
</script>
<style scoped>
    
</style>